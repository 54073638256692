import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../state/mapStateAndDispatch/cart";
import SendPriceToRedux from "./sendPriceToRedux";

class priceInfo extends Component {
  setTotal = number => {
    const { setTotal } = this.props;
    setTotal(number);
  };

  setSubtotal = number => {
    const { setSubtotal } = this.props;
    setSubtotal(number);
  };

  render() {
    const { order, orderType, tip, withUtensils } = this.props;
    const FixOrder = () => {
      let value = 0;
      for (let i = 0, len = order.length; i < len; i += 1) {
        if (Object.prototype.hasOwnProperty.call(order[i], "sauces")) {
          for (const item of Object.keys(order[i].sauces)) {
            if (typeof order[i].sauces[item].price === "number") {
              value += order[i].sauces[item].price;
            }
          }
        }
      }
      return value;
    };
    let subtotal = parseFloat(
      order.reduce(
        (sum, item) =>
          parseFloat(sum) +
          parseFloat(item.price) +
          (item.rnPrice || 0) +
          (item.meatPrice || 0),
        0
      )
    ).toFixed(2);
    if (typeof FixOrder() === "number") {
      subtotal = (
        parseFloat(subtotal) + parseFloat(FixOrder().toFixed(2))
      ).toFixed(2);
    }
    const tax = subtotal * 0.07;
    let total = subtotal * 1.07 + parseFloat(tip);
    if (orderType === "delivery") {
      total = parseFloat(total) + 1.9;
    }
    return (
      <div>
        <div style={{ textAlign: "right" }}>
          <p>With Utensils: {withUtensils}</p>
          <p>Subtotal: ${subtotal}</p>
          <p>Tax: ${tax.toFixed(2)}</p>
          {orderType === "delivery" && <p>Delivery Fee: $1.90</p>}
          <p>Tip: ${tip.toFixed(2)}</p>
        </div>
        <br />
        <p style={{ textAlign: "right" }}>
          <b>Total: ${total.toFixed(2)}</b>
        </p>
        <br />
        <br />
        <SendPriceToRedux
          subtotal={subtotal}
          total={total}
          setSubtotal={this.setSubtotal}
          setTotal={this.setTotal}
        />
      </div>
    );
  }
}

priceInfo.propTypes = {
  setSubtotal: PropTypes.func.isRequired,
  setTip: PropTypes.func.isRequired,
  withUtensils: PropTypes.string.isRequired,
  setTotal: PropTypes.func.isRequired,
  order: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tip: PropTypes.number.isRequired,
  orderType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(priceInfo);
