const returnSauceName = key => {
  if (key === "eelSauce") {
    return "Eel Sauce";
  }
  if (key === "spicyMayo") {
    return "Spicy Mayo";
  }
  if (key === "teriyakiSauce") {
    return "Teriyaki Sauce";
  }
  if (key === "bourbonSauce") {
    return "Bourbon Sauce";
  }
  if (key === "sweetSourSauce") {
    return "Sweet & Sour Sauce";
  }
  if (key === "dumplingSauce") {
    return "Dumpling Sauce";
  }
};

export default returnSauceName;
