const sauces = {
  eelSauce: { name: "Eel Sauce", price: 0 },
  spicyMayo: { name: "Spicy Mayo", price: 0 },
  teriyakiSauce: { name: "Teriyaki Sauce", price: 0 },
  bourbonSauce: { name: "Bourbon Sauce", price: 0 },
  sweetSourSauce: { name: "Sweet & Sour Sauce", price: 0 },
  dumplingSauce: { name: "Dumpling Sauce", price: 0 },
};

const taxRate = 0.07;

export const buildNewOrderItems = state => {
  const { order } = state;

  const orderCopy = JSON.parse(JSON.stringify(order));

  if (order.subtotal * (1 + taxRate) >= 50 && order.paymentMethod === "Cash") {
    // Add 10 Krab Rangoon to the Order Items
    const krabRangoon = {
      name: "Krab Rangoons (10)",
      price: 0,
      sauces,
    };
    orderCopy.items.unshift(krabRangoon);
    return orderCopy.items;
  }
  if (order.subtotal * (1 + taxRate) >= 25 && order.paymentMethod === "Cash") {
    // Add 5 Krab Rangoon to the Order Items
    const krabRangoon = {
      name: "Krab Rangoons (5)",
      price: 0,
      sauces,
    };
    orderCopy.items.unshift(krabRangoon);
    return orderCopy.items;
  }

  return orderCopy.items;
};

const mapStateToProps = state => {
  return {
    order: buildNewOrderItems(state),
  };
};

export default mapStateToProps;
