import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Layout from "../components/layout/layout";
import OrderList from "../components/review/orderList";
import Price from "../components/review/price";
import { mapStateToProps } from "../state/mapStateAndDispatch/customerInfo";
import CustomerInfoReview from "../components/review/customerInfoReview";
import NoItemsInCart from "../components/layout/noItemsInCart";
import Navigation from "../components/review/navigationButtons";
import capitalizeFirstLetter from "../capitalizeFirstLetter";

function review(props) {
  const {
    orderLen,
    orderType,
    firstname,
    lastname,
    phone,
    paymentMethod,
    timeOrderFor,
    customTime,
    address,
    zip,
    CVC,
    expDate,
    ccNumber
  } = props;
  return (
    <Layout>
      <Helmet>
        <title>Chopstick Express | Order Review</title>
        <meta charset="utf-8" />
      </Helmet>
      {orderLen > 0 ? (
        <div style={{ backgroundColor: "rgb(252,252,252)", marginBottom: -8 }}>
          <br />
          <h1>{capitalizeFirstLetter(orderType)} Order Review</h1>
          <br />
          <div style={{ width: "88%", margin: "auto" }}>
            <CustomerInfoReview
              firstname={firstname}
              lastname={lastname}
              phone={phone}
              paymentMethod={paymentMethod}
              timeOrderFor={timeOrderFor}
              customTime={customTime}
              address={address}
              zip={zip}
              CVC={CVC}
              expDate={expDate}
              ccNumber={ccNumber}
              orderType={orderType}
            />
            <br />
            <OrderList />
            <Price />
            <Navigation />
            <br />
            <br />
          </div>
        </div>
      ) : (
        <NoItemsInCart />
      )}
    </Layout>
  );
}

export default connect(mapStateToProps)(review);
