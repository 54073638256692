import React, { useEffect } from "react";
import PropTypes from "prop-types";

export default function sendPriceToRedux(props) {
  const { setSubtotal, subtotal, total, setTotal } = props;

  useEffect(() => {
    setSubtotal(subtotal);
    setTotal(total.toFixed(2));
  }, [subtotal, total]);
  return <div />;
}

sendPriceToRedux.propTypes = {
  setSubtotal: PropTypes.func.isRequired,
  subtotal: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  setTotal: PropTypes.func.isRequired
};
