import React from "react";
import "../../styles/buttons.css";
import "../../styles/layout.css";

export default function noItemsInCart() {
  return (
    <div className="noItemsInCart" style={{ marginBottom: -8 }}>
      <h2>Your cart is empty. Add some items!</h2>
      <br />
      <br />
      <a className="bigRedButton" href="/menu">
        Menu
      </a>
    </div>
  );
}
