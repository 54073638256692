import DeleteOrder from "../actions/cartPage/deleteOrder";
import SetTip from "../actions/cartPage/setTip";
import SetOrderType from "../actions/cartPage/setOrderType";
import ClearAllItems from "../actions/cartPage/clearAllItems";
import SetSubtotal from "../actions/cartPage/setSubtotal";
import SetTotal from "../actions/cartPage/setTotal";
import SetWithUtensils from "../actions/cartPage/setWithUtensils"
import SetSauces from "../actions/cartPage/setSauces";
import SetRequests from "../actions/cartPage/setRequests";
import UpdateRN from "../actions/cartPage/updateRN";
import UpdateSide from "../actions/cartPage/updateSide";
import UpdateMeat from "../actions/cartPage/updateMeat";

export const mapStateToProps = state => ({
  order: state.order.items,
  orderLen: state.order.items.length,
  tip: state.order.tip,
  total: state.order.total,
  subtotal: state.order.subtotal,
  orderType: state.order.orderType,
  pickupAvailable: state.config.availability.pickup,
  deliveryAvailable: state.config.availability.delivery,
  sundayDeliveryAvailable: state.config.availability.sundayDelivery,
  morningDeliveryAvailable: state.config.availability.morningDelivery,
  mondayDeliveryAvailable: state.config.availability.mondayDelivery,
  withUtensils: state.order.withUtensils,
  pickupWaitMinutes: state.config.availability.pickupWaitMinutes,
  deliveryWaitMinutes: state.config.availability.deliveryWaitMinutes,
});

export const mapDispatchToProps = dispatch => ({
  deleteOrder: entry => {
    dispatch(DeleteOrder(entry));
  },
  setRequests: (req, num) => {
    dispatch(SetRequests(req, num));
  },
  setTip: tip => {
    dispatch(SetTip(tip));
  },
  setWithUtensils: withUtensils => {
    dispatch(SetWithUtensils(withUtensils));
  },
  setOrderType: orderType => {
    dispatch(SetOrderType(orderType));
  },
  clearAllItems: () => {
    dispatch(ClearAllItems());
  },
  setSubtotal: num => {
    dispatch(SetSubtotal(num));
  },
  setTotal: num => {
    dispatch(SetTotal(num));
  },
  setSauces: (sauces, itemNumber) => {
    dispatch(SetSauces(sauces, itemNumber));
  },
  updateRN: (rn, rnPrice, itemNumber) => {
    dispatch(UpdateRN(rn, rnPrice, itemNumber));
  },
  updateSide: (sideChoice, itemNumber) => {
    dispatch(UpdateSide(sideChoice, itemNumber));
  },
  updateMeat: (meatChoice, meatPrice, itemNumber) => {
    dispatch(UpdateMeat(meatChoice, meatPrice, itemNumber));
  },
});
