export const SetCCNumber = ccNumber => ({
  type: "SET_CC_NUMBER",
  ccNumber
});

export const SetExpDate = expDate => ({
  type: "SET_EXP_DATE",
  expDate
});

export const SetCVC = CVC => ({
  type: "SET_CVC",
  CVC
});
