import React from "react";
import { Link } from "gatsby";
import "../../styles/buttons.css";

export default function navigationButtons() {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Link className="redButton" role="button" tabIndex={0} to="/customerinfo">
        Your Info
      </Link>
      <Link
        className="blueButton"
        role="button"
        tabIndex={0}
        to="/final"
        state={{
          fromReview: true
        }}
      >
        Checkout
      </Link>
    </div>
  );
}
