import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import mapStateToProps from "../../state/mapStateAndDispatch/customOrder";
import { mapDispatchToProps } from "../../state/mapStateAndDispatch/cart";
import "../../styles/buttons.css";
import returnSauceName from "../cart/orderList/returnSauceName";

const nanoid = require("nanoid");

function checkRNPrice(val) {
  if (val) {
    if (val > 0) {
      return true;
    }
  }
  return false;
}

function checkMeat(meatChoice) {
  if (meatChoice) {
    if (meatChoice !== "None") {
      return true;
    }
  }
  return false;
}

function orderList(props) {
  const { order } = props;
  console.log(mapStateToProps)
  const list = order.map((item, index) => {
    const finalPrice =
      parseFloat(item.price) +
      (parseFloat(item.rnPrice) || 0) +
      (parseFloat(item.meatPrice) || 0) +
      parseFloat(item.sauces.eelSauce.price) +
      parseFloat(item.sauces.spicyMayo.price) +
      parseFloat(item.sauces.teriyakiSauce.price) +
      parseFloat(item.sauces.bourbonSauce.price) +
      parseFloat(item.sauces.sweetSourSauce.price) +
      parseFloat(item.sauces.dumplingSauce.price);
    return (
      <Grid
        container
        style={{ textAlign: "left" }}
        key={nanoid()}
        alignItems="flex-start"
      >
        <Grid item sm={8} xs={6}>
          <p>
            {index + 1}. {item.name}
          </p>
          <ul style={{ listStyleType: "none" }}>
            {item.sideChoice && (
              <li>
                <b>Side:</b> {item.sideChoice}
              </li>
            )}
            {item.rnChoice && (
              <li>
                <b>Rice/Noodle:</b> {item.rnChoice}
                {checkRNPrice(item.rnPrice) && (
                  <span>{` (+$${item.rnPrice.toFixed(2)})`}</span>
                )}
              </li>
            )}
            {checkMeat(item.meatChoice) && (
              <li>
                <b>Meat Choice:</b> {item.meatChoice}{" "}
                <span>{` (+$${item.meatPrice.toFixed(2)})`}</span>
              </li>
            )}
            {item.requests && (
              <li>
                <b>Requests:</b> {item.requests}
              </li>
            )}
            {Object.keys(item.sauces).filter(key => item.sauces[key].number > 0)
              .length > 0 && (
              <div>
                <b>
                  <li>Sauces:</li>
                </b>
                {Object.keys(item.sauces)
                  .filter(key => item.sauces[key].number > 0)
                  .map(key => (
                    <li>
                      {`${returnSauceName(key)} ` +
                        `x${item.sauces[key].number} (+$${item.sauces[
                          key
                        ].price.toFixed(2)})`}
                    </li>
                  ))}
              </div>
            )}
          </ul>
        </Grid>
        <Grid item sm={2} xs={3} />
        <Grid item sm={2} style={{ textAlign: "right" }} xs={3}>
          <p>${finalPrice.toFixed(2)}</p>
        </Grid>
      </Grid>
    );
  });
  return (
    <div style={{ textAlign: "left" }}>
      {list}
      <br />
    </div>
  );
}

orderList.propTypes = {
  order: PropTypes.instanceOf(Array).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(orderList);
