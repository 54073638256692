import React from "react";
import "../../styles/review.css";
import "../../styles/global.css";

export default function customerInfoReview(props) {
  const {
    firstname,
    lastname,
    phone,
    paymentMethod,
    timeOrderFor,
    customTime,
    address,
    zip,
    CVC,
    expDate,
    ccNumber,
    orderType
  } = props;
  return (
    <div>
      <div className="biggerThan600">
        <h2 style={{ textAlign: "center" }}>Your Information</h2>
        <div className="leftGrid">
          <div className="infoA" style={{ textAlign: "left" }}>
            <p>
              <b>Name:</b> {firstname} {lastname}{" "}
            </p>
            <p>
              <b>Order Time:</b>{" "}
              {timeOrderFor === "As Soon As Possible"
                ? timeOrderFor
                : customTime}
            </p>
            {orderType === "delivery" && (
              <p>
                <b>Address: </b>
                {address}, {zip}
              </p>
            )}
            {paymentMethod === "Credit" ? (
              <p>
                <b>Exp. Date:</b> {expDate}
              </p>
            ) : (
              <div />
            )}
          </div>
          <div className="infoB" style={{ textAlign: "left" }}>
            <p>
              <b>Phone:</b> {phone}{" "}
            </p>
            <p>
              <b>Payment Method:</b> {paymentMethod}
            </p>
            {paymentMethod === "Credit" ? (
              <p>
                <b>Card Number:</b> {ccNumber}
              </p>
            ) : (
              <div />
            )}
            {paymentMethod === "Credit" ? (
              <p>
                <b>CVC:</b> {CVC}
              </p>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
      <div className="mobile">
        <h2 style={{ textAlign: "center" }}>Your Information</h2>
        <div style={{ width: "88%", margin: "auto" }}>
          <p>
            <b>Name:</b> {firstname} {lastname}{" "}
          </p>
          <p>
            <b>Phone:</b> {phone}{" "}
          </p>
          <p>
            <b>Payment:</b> {paymentMethod}
          </p>
          <p>
            <b>Order Time:</b>{" "}
            {timeOrderFor === "As Soon As Possible" ? (
              <span>{timeOrderFor}</span>
            ) : (
              <span>{customTime}</span>
            )}
          </p>
          <p>
            {paymentMethod === "Credit" ? (
              <span>
                <b>Card Number:</b> {ccNumber}
              </span>
            ) : (
              <div />
            )}
          </p>
          <p>
            {paymentMethod === "Credit" ? (
              <span>
                <b>Exp. Date:</b> {expDate}
              </span>
            ) : (
              <div />
            )}
          </p>
          <p>
            {paymentMethod === "Credit" ? (
              <span>
                <b>CVC:</b> {CVC}
              </span>
            ) : (
              <div />
            )}
          </p>
          <p>
            {orderType === "delivery" && (
              <span>
                <b>Address: </b>
                {address}, {zip}
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
