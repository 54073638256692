import SetFirstName from "../actions/customerInfo/setFirstName";
import SetLastName from "../actions/customerInfo/setLastName";
import SetPhone from "../actions/customerInfo/setPhone";
import SetPaymentMethod from "../actions/customerInfo/setPaymentMethod";
import SetTimeOrderFor from "../actions/customerInfo/setTimeOrderFor";
import SetCustomTime from "../actions/customerInfo/setCustomTime";
import {
  SetCCNumber,
  SetExpDate,
  SetCVC,
} from "../actions/customerInfo/setCreditCardInfo";
import SetAddress from "../actions/customerInfo/setAddress";
import SetZip from "../actions/customerInfo/setZip";
import SetPaymentPreferences from "../actions/customerInfo/setPaymentPreferences";
import SetUID from "../actions/customerInfo/setUID";

export const mapStateToProps = state => ({
  firstname: state.customer.firstname,
  lastname: state.customer.lastname,
  phone: state.customer.phone,
  paymentMethod: state.order.paymentMethod,
  orderType: state.order.orderType,
  timeOrderFor: state.order.timeOrderFor,
  customTime: state.order.customTime,
  ccNumber: state.order.ccNumber,
  expDate: state.order.expDate,
  CVC: state.order.CVC,
  address: state.customer.address,
  zip: state.customer.zip,
  orderLen: state.order.items.length,
  paymentPreferences: state.customer.paymentPreferences,
  subtotal: state.order.subtotal,
});

export const mapDispatchToProps = dispatch => ({
  setFirstName: firstname => {
    dispatch(SetFirstName(firstname));
  },
  setLastName: lastname => {
    dispatch(SetLastName(lastname));
  },
  setPhone: phone => {
    dispatch(SetPhone(phone));
  },
  setPaymentMethod: method => {
    dispatch(SetPaymentMethod(method));
  },
  setTimeOrderFor: timeOrderFor => {
    dispatch(SetTimeOrderFor(timeOrderFor));
  },
  setCustomTime: customTime => {
    dispatch(SetCustomTime(customTime));
  },
  setNumber: num => {
    dispatch(SetCCNumber(num));
  },
  setExpDate: expDate => {
    dispatch(SetExpDate(expDate));
  },
  setCVC: CVC => {
    dispatch(SetCVC(CVC));
  },
  setAddress: address => {
    dispatch(SetAddress(address));
  },
  setZip: zip => {
    dispatch(SetZip(zip));
  },
  setPaymentPreferences: paymentPreferences => {
    dispatch(SetPaymentPreferences(paymentPreferences));
  },
  setUID: uid => {
    dispatch(SetUID(uid));
  }
});
